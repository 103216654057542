<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-row class="mt-8">
        <v-col cols="12">
          <div>
            <v-card class="card-shadow border-radius-xl">
              <div class="card-header-padding">
                <div class="d-flex align-center">
                  <div>
                    <h5 class="font-weight-bold text-h5 text-typo mb-0">
                      Document State
                    </h5>
                    <p class="text-sm text-body mb-0">
                      Possible states of a document
                    </p>
                  </div>

                  <v-dialog v-model="dialog" max-width="500px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        elevation="0"
                        :ripple="false"
                        height="43"
                        class="
                          font-weight-bold
                          text-capitalize
                          ms-auto
                          btn-primary
                          bg-success
                          py-3
                          px-6
                          ms-3
                        "
                        >New Document State</v-btn
                      >
                    </template>
                    <v-card class="card-shadow border-radius-xl">
                      <div class="card-header-padding card-border-bottom">
                        <span class="font-weight-bold text-h5 text-typo mb-0">{{
                          formTitle
                        }}</span>
                      </div>
                      <v-card-text class="card-padding">
                        <v-container class="px-0">
                          <v-row>
                            <v-col cols="6">
                              <v-text-field
                                v-model="editedItem.master_state"
                                hide-details
                                class="
                                  input-style
                                  font-size-input
                                  text-light-input
                                  placeholder-light
                                  input-icon
                                "
                                dense
                                flat
                                filled
                                solo
                                height="43"
                                placeholder="Document State"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions class="card-padding d-flex justify-end">
                        <v-btn
                          @click="close"
                          elevation="0"
                          :ripple="false"
                          height="43"
                          class="
                            font-weight-bold
                            text-capitalize
                            btn-ls btn-secondary
                            bg-gradient-light
                            py-3
                            px-6
                          "
                          >Cancel</v-btn
                        >

                        <v-btn
                          @click="save"
                          elevation="0"
                          :ripple="false"
                          height="43"
                          class="
                            font-weight-bold
                            text-capitalize
                            btn-ls btn-primary
                            bg-success
                            py-3
                            px-6
                          "
                          >Save</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </div>

              <v-card-text class="px-0 py-0">
                <v-data-table
                  :headers="headers"
                  :items="items"
                  :search="search"
                  class="table"
                  :page.sync="page"
                  hide-default-footer
                  @page-count="pageCount = $event"
                  :items-per-page="itemsPerPage"
                  mobile-breakpoint="0"
                >
                  <template v-slot:top>
                    <v-toolbar flat height="80">
                      <v-row>
                        <v-col cols="12" md="5">
                          <v-text-field
                            hide-details
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                            "
                            dense
                            flat
                            filled
                            solo
                            height="43"
                            v-model="search"
                            placeholder="Search"
                          >
                            <template slot="prepend-inner">
                              <v-icon color="#adb5bd" size=".875rem"
                                >fas fa-search</v-icon
                              >
                            </template>
                          </v-text-field>
                        </v-col>
                      </v-row>

                      <v-spacer></v-spacer>

                      <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card
                          class="card-shadow card-padding border-radius-xl"
                        >
                          <v-card-title
                            class="pt-0 text-h5 text-typo justify-center"
                            >Are you sure you want to delete this
                            item?</v-card-title
                          >
                          <v-card-actions class="pb-0">
                            <v-spacer></v-spacer>
                            <v-btn
                              @click="closeDelete"
                              elevation="0"
                              :ripple="false"
                              height="43"
                              class="
                                font-weight-bold
                                text-capitalize
                                btn-ls
                                bg-gradient-light
                                py-3
                                px-6
                              "
                              >Cancel</v-btn
                            >

                            <v-btn
                              @click="deleteItemConfirm"
                              elevation="0"
                              :ripple="false"
                              height="43"
                              class="
                                font-weight-bold
                                text-capitalize
                                btn-ls btn-primary
                                bg-success
                                py-3
                                px-6
                              "
                              >Ok</v-btn
                            >
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-btn
                      @click="editItem(item)"
                      icon
                      elevation="0"
                      :ripple="false"
                      height="28"
                      min-width="36"
                      width="36"
                      class=""
                      color="green"
                      >Edit
                    </v-btn>

                    <v-btn
                      @click="deleteItem(item)"
                      icon
                      elevation="0"
                      :ripple="false"
                      height="28"
                      min-width="36"
                      width="36"
                      class="ml-5"
                      color="#FF0000"
                    >
                      delete
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card-text>
              <v-card-actions class="card-padding">
                <v-row>
                  <v-col cols="6" lg="4" class="d-flex align-center">
                    <span class="text-body me-3 text-sm">Items per page:</span>
                    <v-select
                      hide-details
                      :items="paginationLimits"
                      item-text="label"
                      item-value="id"
                      outlined
                      background-color="rgba(255,255,255,.9)"
                      color="rgba(0,0,0,.6)"
                      light
                      v-model="itemsPerPage"
                      placeholder="Items per page"
                      class="
                        font-size-input
                        placeholder-lighter
                        text-color-light
                        input-alternative input-focused-alternative input-icon
                      "
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="6" class="ml-auto d-flex justify-end">
                    <v-pagination
                      prev-icon="fa fa-angle-left"
                      next-icon="fa fa-angle-right"
                      class="pagination"
                      color="#38bd34"
                      v-model="page"
                      :length="pageCount"
                      circle
                    ></v-pagination>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
// import axios from "axios";
import api from "../api";

import { paginationLimits } from "../../../../global-data/paginationLimits";

export default {
  name: "paginated-tables",
  data() {
    return {
      page: 1,
      pageCount: 0,
      paginationLimits: paginationLimits,
      itemsPerPage: 10,

      dialog: false,
      dialogDelete: false,
      items: [],
      search: "",
      editedIndex: -1,
      editedItem: {
        master_state: "",
        id: 0,
      },
      defaultItem: {
        master_state: "",
        id: 0,
      },
      headers: [
        {
          text: "Document Master State",
          align: "start",
          sortable: true,
          value: "master_state",
        },

        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },

  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      // axios
      //   .get("master-state")
      //   .then((res) => {
      //     this.items = res.data;
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   })
      //   .finally(() => {
      //     this.isLoading = false;
      //   });

      this.items = await api.get();
    },

    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    async deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      await api.delete(this.editedItem.id);
      this.items.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      if (this.editedIndex > -1) {
        await api.edit(this.editedItem.id, this.editedItem);
        Object.assign(this.items[this.editedIndex], this.editedItem);
      } else {
        let item = await api.create(this.editedItem);
        this.items.push(item);
      }
      this.close();
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "New Document State"
        : "Edit Document State";
    },
    pages() {
      return this.pagination.rowsPerPage
        ? Math.ceil(this.items.length / this.pagination.rowsPerPage)
        : 0;
    },
  },
};
</script>
